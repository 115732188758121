import clsx from 'clsx';
import { Header1, Overline } from 'components/UI/Brand/text';
import { media } from 'style/sizes';
import MoreInformationBar, {
  IMoreInfoLink,
} from 'components/BookingFunnel/MoreInformationBar';
import { BrandProvider, Tag, Icon, LinkText } from 'components';
import translate from 'translate';
import { getFullDateFormat } from 'utils/multilanguage-support';
import BackToButton from 'components/UI/Buttons/BackButton/BackToButton';
import { Link } from 'react-router-dom';
import { IShip } from 'interfaces/IShip';
import { colors } from 'style/colors';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { format } from 'date-fns';
import { getCalendarLocale } from 'utils/dates';
import { getLanguage } from 'utils/getLanguage';
import React from 'react';
import { ImageView } from 'components/UI/ImageView/ImageView';
import styles from './styles.module.scss';

interface IVoyageHeaderProps {
  isCoastal: boolean;
  isBookingFunnel?: boolean;
  title: string;
  departureDate?: string;
  duration: string;
  ships?: IShip[];
  imageUrl?: string;
  mapUrl?: string;
  imageAltText?: string;
  mapAltText?: string;
  moreInfoLinks: IMoreInfoLink[];
  voyageId?: string;
  destinationId?: string;
  tourType?: string;
}

const VoyageHeader = ({
  isCoastal,
  isBookingFunnel = false,
  title,
  departureDate,
  duration,
  ships,
  imageUrl,
  mapUrl,
  imageAltText,
  mapAltText,
  moreInfoLinks,
  voyageId,
  destinationId,
  tourType,
}: IVoyageHeaderProps) => {
  const isLaptop = useMediaQuery(media.minLaptop);
  const language = getLanguage();
  const locale = getCalendarLocale(language);

  return (
    <>
      <div
        className={clsx(styles.root, {
          [styles.isCoastal]: isCoastal,
        })}
      >
        {!isLaptop && isBookingFunnel && (
          <BackToButton hasIcon isCoastal={isCoastal} />
        )}
        <div
          className={clsx(styles.container, {
            [styles.isBookingFunnel]: isBookingFunnel,
          })}
        >
          <div className={styles.textContainer}>
            <Tag
              title={
                isCoastal
                  ? translate('BookingSummary_CoastalExpress')
                  : translate('BookingSummary_Expedition')
              }
              type="invertedvoyage"
              isCoastal={isCoastal}
            />
            <BrandProvider fixedBrand={isCoastal ? 'coastal' : 'expedition'}>
              <Header1 className={styles.title}>{title}</Header1>
            </BrandProvider>
            <div className={styles.infoContainer}>
              {isBookingFunnel && (
                <Overline>
                  {format(departureDate, getFullDateFormat(), { locale })}
                </Overline>
              )}
              <Overline>{duration}</Overline>
              {ships.map((ship) => (
                <LinkText
                  target="_blank"
                  as={Link}
                  to={`/learn/ship/${ship.shipCode}`}
                  className={styles.linkStyledAsOverline}
                >
                  {ship?.heading || ship?.shipName}
                  <Icon
                    iconType="newTab"
                    height={13}
                    width={13}
                    color={colors.shared.white}
                    style={{ marginLeft: 5 }}
                  />
                </LinkText>
              ))}
            </div>
          </div>
          {isLaptop && isBookingFunnel ? (
            <figure className={styles.imageContainer}>
              <img className={styles.image} src={imageUrl} alt={imageAltText} />
            </figure>
          ) : isLaptop && mapUrl ? (
            <ImageView
              src={mapUrl}
              alt={mapAltText}
              containerClassName={styles.mapContainer}
              imageClassName={styles.map}
            />
          ) : null}
        </div>
      </div>
      <MoreInformationBar
        isSticky={!isBookingFunnel}
        listOfLinks={moreInfoLinks}
        isCoastal={isCoastal}
        isBookingFunnel={isBookingFunnel}
        voyageId={voyageId}
        destinationId={destinationId}
        tourType={tourType}
      />
    </>
  );
};

export default VoyageHeader;
